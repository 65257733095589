import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Img from "react-cool-img";
import { Layout, ReadNext, Subscribe } from "../components/common";
import { MetaData } from "../components/common/meta";

import signature from "../images/signature.png"
import { Disqus } from 'gatsby-plugin-disqus';

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;
    const newUrl = post.url.replace('https://xlog.x-hub.io/', 'https://blog.x-hub.io/')
    let disqusConfig = {
        url: newUrl,
        identifier: post.id,
        title: post.title,
    }

    return (
        <>
            <Helmet>
                <meta name="title" content={`${post.title} - xHub Blog`} />
                <meta
                    name="description"
                    content={post.excerpt.substring(0, 200)}
                />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={`${newUrl}${post.slug}`} />
                <meta property="og:title" content={`${post.title} - xHub Blog`} />
                <meta
                    property="og:description"
                    content={post.excerpt.substring(0, 200)}
                />
                {post.feature_image ? <meta property="og:image" content={post.feature_image} /> : <meta property="og:image" content="../images/logo.svg" /> }

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={`${newUrl}${post.slug}`} />
                <meta property="twitter:title" content={`${post.title} - xHub Blog`} />
                <meta
                    property="twitter:description"
                    content={post.excerpt.substring(0, 200)}
                />
                {post.feature_image ? <meta property="twitter:image" content={post.feature_image} /> : <meta property="twitter:image" content="../images/logo.svg" /> }
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
                <title>{post.title} - xHub Blog</title>
            </Helmet>
            <Layout isPost={true}>
                <div>
                    <div className="relative">
                        <h1 className="leading-none lg:text-6xl text-4xl text-white text-left mb-20 lg:px-56 px-2 absolute bottom-0 z-10">{post.title}</h1>
                        <div className="bg-black w-full h-full bottom-0 left-0 absolute bg-opacity-50"></div>
                        <Img className="w-full" src={post.feature_image} alt={post.title} />
                    </div>
                    <div className="lg:px-56 px-2 text-black dark:text-white">
                    <div className="flex justify-between py-24">
                        <div className="flex space-x-8">
                            {post.primary_author.profile_image ? (
                                <img
                                    className="xl:w-20 w-20 xl:h-20 h-20 rounded-b-full rounded-tl-full"
                                    src={post.primary_author.profile_image}
                                    alt={post.primary_author.name}
                                />
                            ) : (
                                <img
                                    className=""
                                    src="/images/icons/avatar.svg"
                                    alt={post.primary_author.name}
                                />
                            )}
                            <div>
                                <a
                                    href={`${location.origin}/author/${post.primary_author.slug}/`}
                                    className="hover:no-underline text-primary-500 dark:text-secondary-500 hover:text-secondary-500"
                                >
                                    <h4 className="leading-none mb-3 uppercase text-2xl font-bold">
                                        {post.primary_author.name}
                                    </h4>
                                </a>
                                <p>
                                    {post.published_at_pretty} ·{" "}
                                    {post.reading_time}min 📖
                                </p>
                            </div>
                        </div>
                        <div className="flex">
                            {post.primary_author.facebook ? (
                                <a
                                    href={`https://www.facebook.com/${post.primary_author.facebook}`}
                                    target="_blank"
                                >
                                    <div className="py-4 font-body hover:shadow-lg lg:px-16 px-6 transition ease-out text-white rounded-tl-lg rounded-bl-lg border-t-2 border-b-2 border-l-2 border-primary-100 border-solid border-opacity-20">
                                        <svg
                                            className="h-10 text-primary-500 hover:text-seconary-500 dark:text-secondary-500 dark:hover:text-primary-500 transition ease-out"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fab"
                                            data-icon="facebook"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                                            ></path>
                                        </svg>
                                    </div>
                                </a>
                            ) : null}
                            {post.primary_author.twitter ? (
                                <a
                                    href={`https://twitter.com/${post.primary_author.twitter}`}
                                    target="_blank"
                                >
                                    <div className="py-4 font-body hover:shadow-lg lg:px-16 px-6 transition ease-out text-white rounded-tr-lg rounded-br-lg border-t-2 border-b-2 border-l-2 border-r-2 border-primary-100 border-solid border-opacity-20">
                                        <svg
                                            className="h-10 text-primary-500 hover:text-seconary-500 dark:text-secondary-500 dark:hover:text-primary-500 transition ease-out"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fab"
                                            data-icon="twitter"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                            ></path>
                                        </svg>
                                    </div>
                                </a>
                            ) : null}
                        </div>
                    </div>
                    <section className="post-full-content" >
                        {/* The main post content */}
                        <section 
                            id="target-el"
                            className="content-body bg-white dark:text-white dark:bg-black load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: post.html }}
                        />
                    </section>
                    <div className="relative py-56 w-full">
                    <div className="flex pt-56">
                            {post.primary_author.facebook ? (
                                <a rel="noopener"  
                                    className=" w-6/12"
                                    href={`https://www.facebook.com/${post.primary_author.facebook}`}
                                    target="_blank"
                                >
                                    <div className="py-4 font-body hover:shadow-lg px-12 transition ease-out text-white rounded-tl-lg rounded-bl-lg border-t-2 border-b-2 border-l-2 border-primary-100 border-solid border-opacity-20">
                                        <svg
                                            className="h-10 w-full text-primary-500 hover:text-seconary-500 dark:text-secondary-500 dark:hover:text-primary-500 transition ease-out"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fab"
                                            data-icon="facebook"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                                            ></path>
                                        </svg>
                                    </div>
                                </a>
                            ) : null}
                            {post.primary_author.twitter ? (
                                <a
                                    className=" w-6/12"
                                    href={`https://twitter.com/${post.primary_author.twitter}`}
                                    target="_blank"
                                >
                                    <div className="py-4 font-body hover:shadow-lg px-12 transition ease-out text-white rounded-tr-lg rounded-br-lg border-t-2 border-b-2 border-l-2 border-r-2 border-primary-100 border-solid border-opacity-20">
                                        <svg
                                            className="h-10 w-full text-primary-500 hover:text-seconary-500 dark:text-secondary-500 dark:hover:text-primary-500 transition ease-out"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fab"
                                            data-icon="twitter"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                            ></path>
                                        </svg>
                                    </div>
                                </a>
                            ) : null}
                        </div>
                        <div className=" pt-20">
                        <div className="lg:flex justify-between flex-col space-y-4">
                        <div className="flex space-x-8">
                            {post.primary_author.profile_image ? (
                                <img
                                    className="xl:w-20 w-14 xl:h-20 h-14 rounded-b-full rounded-tl-full"
                                    src={post.primary_author.profile_image}
                                    alt={post.primary_author.name}
                                />
                            ) : (
                                <img
                                    className=""
                                    src="/images/icons/avatar.svg"
                                    alt={post.primary_author.name}
                                />
                            )}
                            <div>
                                <a
                                    href={`${location.origin}/author/${post.primary_author.slug}/`}
                                    className="hover:no-underline text-primary-500 dark:text-secondary-500 dark:hover:text-primary-500 hover:text-secondary-500"
                                >
                                    <h4 className="leading-none mb-3 uppercase text-2xl font-bold">
                                        {post.primary_author.name}
                                    </h4>
                                </a>
                                <p className="general--p-styles w-8/12">
                                    {post.primary_author.bio}
                                </p>
                            </div>
                        </div>
                                <p className="general--p-styles flex justify-end space-x-4">
                                   <div> Tags: </div> {
                                        post.tags.slice(0, 3).map(tag => {
                                            return <div><a rel="noopener" href={`https://blog.x-hub.io/tag/${tag.slug}/`} className="whitespace-nowrap text-primary-500 dark:text-secondary-500 dark:hover:text-primary-500 hover:no-underline text-semibold hover:text-secondary-500 transition ease-out"> {tag.name}</a></div> 
                                        }) 
                                    }
                                </p>
                            </div>
                        <div className="border-b-2 border-solid border-gray-100 my-20"></div>
                            <div>
                                <Disqus className="dark:text-white"
                                    config={disqusConfig}
                                />
                            </div>
                            
                        </div>

                        
                         <div className="absolute bottom-0 right-0 lg:w-6/12 md:w-4/12 transform -rotate-12">
                            <Img src={signature} alt="signature" />
                        </div>
                    </div>
                </div>
                </div>
                <ReadNext author={post.primary_author.name} authorSlug={post.primary_author.slug} pageSlug={post.slug}/>
                <Subscribe />
            </Layout>
        </>
    );
};

export default Post;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
